import paths from 'routes/paths';
import { ActionToDo } from 'components/Button';
import {
    verifilinkIcon,
    contactIcon,
    documentIcon,
    faqIcon,
    paymentIcon,
    summaryIcon,
    ballotCheckIcon,
    fileContractIcon,
} from 'assets/img';
import { Col, Row } from 'components/Layout';
import styled from 'styled-components';
import { useVerifilinkRedirect } from 'hooks';
import { useContext, useMemo } from 'react';
import { FeatureContext } from 'contexts';
import intl from 'library/reactIntl';
import OpenContactWidget from 'utilities/OpenContactWidget';

const actionItems = [
    {
        title: 'Payments',
        icon: paymentIcon,
        description:
            'Make a payment, view your payment history or edit an existing payment method.',
        link: paths.PAYMENTS,
    },
    {
        title: 'Documents',
        icon: documentIcon,
        description:
            'View and upload all the documents required by us to complete your case.',
        link: paths.DOCUMENTS,
    },

    {
        title: 'Transcript Summary',
        icon: summaryIcon,
        description:
            'View a visual break down of your wage and income transcripts and see your notices from the IRS.',
        link: paths.TRANSCRIPT,
    },
    {
        title: 'Financial Summary',
        link: paths.FINANCIAL_SUMMARY,
        icon: fileContractIcon,
        description: 'View a detailed breakdown of all your transactions.'
    },
    {
        title: 'FAQ’s',
        icon: faqIcon,
        description: `Frequently asked questions about cases and the ${intl.formatMessage(
            { id: 'app.brand', defaultMessage: 'Taxrise' }
        )} process.`,
        link: paths.FAQ,
    },
    {
        title: 'Get in Touch',
        icon: contactIcon,
        description: 'Send a message to your case manager ',
        onClick: OpenContactWidget,
    },
];

// Redirect to Verifilink button
const VerifilinkTask = () => {
    const [, redirectToVerifilink] = useVerifilinkRedirect();

    return (
        <Column xs={24} md={8}>
            <ActionToDo
                icon={verifilinkIcon}
                title="Verifilink"
                description="Create your full financial picture and speed up your case by completing our online questionnaire. "
                onClick={redirectToVerifilink}
            />
        </Column>
    );
};

// Redirect to Verifilink Summary button
const VerifilinkSummary = () => {
    const [, , redirectToSummary] = useVerifilinkRedirect();

    return (
        <Column xs={24} md={8}>
            <ActionToDo
                icon={ballotCheckIcon}
                title="Verifilink Summary & Form 433-A"
                description="View your verifilink results and download your form 433-A to sign return."
                onClick={redirectToSummary}
            />
        </Column>
    );
};

function ActivitiesTab() {
    const { featureStatus } = useContext(FeatureContext);
    const { transcript, verifilink, vlink_complete } = featureStatus;

    const itemList = useMemo(() => {
        const actions = [...actionItems];

        if (!transcript) {
            actions.splice(2, 1);
        }

        return actions;
    }, [transcript]);

    const verifilinkAction = useMemo(() => {
        if (!verifilink && !vlink_complete) {
            return null;
        } else if (vlink_complete) {
            return <VerifilinkSummary />;
        } else {
            return <VerifilinkTask />;
        }
    }, [verifilink, vlink_complete]);

    return (
        <Row gutter={14} type="flex">
            {verifilinkAction}
            {itemList.map((data, index) => (
                <Column xs={24} md={8} key={index}>
                    <ActionToDo
                        icon={data.icon}
                        title={data.title}
                        description={data.description}
                        link={data.link}
                        onClick={data.onClick}
                    />
                </Column>
            ))}
        </Row>
    );
}

const Column = styled(Col)`
    @media (min-width: 768px) {
        margin-bottom: 32px;
    }
`;

export default ActivitiesTab;
