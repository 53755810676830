/* eslint-disable no-undef */
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { TextInput, PasswordInput, CheckboxField } from 'components/FormFields';
import Button from 'components/Button';
import { Link, useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useApi, endpoints } from 'api';
import paths from 'routes/paths';
import { PageHeader } from 'components/Header';
import Version from 'components/Version';
import store from 'store';
import Tooltip, { TooltipTitle } from 'components/Tooltip';
import { ContentWrap } from 'components/Layout';
import Message from 'components/Message';
import {
    EMAIL_REGEX,
    CHARACTERS_TYPE_PATTERN,
    FORBIDDEN_SPECIAL_SYMBOLS_PATTERN,
    LETTER_CASES_PATTERN,
    SPECIAL_SYMBOLS_PATTERN,
} from 'const';
import { useDisplayServerErrors } from 'hooks';
import { FormattedText } from 'utilities';

const appName = process.env['REACT_APP_EMAIL_APP_NAME'];
const baseUrl = process.env['REACT_APP_BASE_URL'];

function SignUp(props) {
    const [initialValues, updateInitialValues] = useState({
        case_id: '',
        email: '',
        password: '',
        retype_password: '',
        terms_condition: false,
    });
    const history = useHistory();
    const { search } = useLocation();
    const [api] = useApi(false);
    const [errorMsg, setErrorMsg] = useState();
    const [passwordErrors, setPasswordErrors] = useState({
        isLengthError: true,
        isLetterCasesError: true,
        isCharactersTypeError: true,
        isSpecialSymbolsError: true,
    });
    const [showErrMsg, hideErrMsg] = useState(false);
    const showServerErrors = useDisplayServerErrors();

    useEffect(() => {
        const urlParams = new URLSearchParams(search);
        const caseId = urlParams.get('case_id');
        const email = urlParams.get('email');

        if (caseId) {
            updateInitialValues((initValue) => ({
                ...initValue,
                case_id: caseId,
                email: email,
            }));
        }
    }, [search]);

    const signUp = async (data, { setErrors }) => {
        store.set('case_id', data.case_id);
        store.set(
            'redirect_url',
            window.location.href.split('?')[0] + '/' + paths.EMAIL_REDIRECT_URL
        );
        store.set('email_app_name', appName);

        data['app'] = appName;
        data['app_base_url'] = baseUrl;

        try {
            const req = await api({
                method: 'POST',
                url: endpoints.SIGN_UP,
                data,
            });

            const res = await req.data;
            if (res) {
                setTimeout(() => history.push(paths.SEND_OTP), 5000);
            }
            
            // if (res) {
            //     resendEmail(data);
            // }
        } catch (error) {
            const { data, status } = error.response;

            if (status === 422) {
                showServerErrors(data.detail, setErrors);
            }

            const result = JSON.parse(data.detail);
            const { msg, is_email_verified } = result;
            setErrorMsg(msg);
            hideErrMsg(true);
            if (!is_email_verified && is_email_verified !== undefined && !is_phone_verified && is_phone_verified !== undefined) {
                store.set('key', 'only_resend');
                setTimeout(() => history.push(paths.SEND_OTP), 5000);
            }
        }
    };

    const resendEmail = async (data) => {
        try {
            data['redirect_url'] =
                window.location.href.split('?')[0] +
                '/' +
                paths.EMAIL_REDIRECT_URL;
            data['email_app_name'] = appName;

            const req = await api({
                method: 'POST',
                url: endpoints.RESEND_EMAIL,
                data,
            });

            const res = await req.data;

            if (res) {
                history.push(paths.EMAIL_RESEND);
            }
        } catch (error) {
            const { data } = error.response;
            setErrorMsg(data.detail);
        }
    };

    const handlePassword = (value) => {
        setPasswordErrors({
            isLengthError: value.length < 8,
            isLetterCasesError: !LETTER_CASES_PATTERN.test(value),
            isCharactersTypeError: !CHARACTERS_TYPE_PATTERN.test(value),
            isSpecialSymbolsError: !SPECIAL_SYMBOLS_PATTERN.test(value) || !FORBIDDEN_SPECIAL_SYMBOLS_PATTERN.test(value),
        })
    }

    return (
        <Wrapper>
            <section style={{ flex: 1 }}>
                <PageHeader className="width-660">
                    <h1 className="mb-20">
                        Sign up for your{' '}
                        <FormattedText id="app.brand" defaultValue="TaxRise" />{' '}
                        client portal account.
                    </h1>

                    <p className="mb-0">
                        Make payments, upload documents and gain access to
                        essential{' '}
                        <FormattedText id="app.brand" defaultValue="TaxRise" />{' '}
                        features.
                    </p>
                </PageHeader>
                <ContentWrap width="490px">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={signUp}
                        validationSchema={validationRules}
                        validateOnMount
                        enableReinitialize
                    >
                        {({ handleSubmit, isSubmitting, handleBlur }) => (
                            <form onSubmit={handleSubmit}>
                                {showErrMsg === true && (
                                    <Message message={errorMsg} />
                                )}
                                <TextInput name="case_id" label="Case ID" />
                                <TextInput name="email" label="Email Address" />
                                <TooltipDesign>
                                    <Tooltip
                                        placement="bottomLeft"
                                        title={<TooltipTitle errors={passwordErrors} />}
                                        trigger={['focus', 'click']}
                                        getPopupContainer={(triggerNode) =>
                                            triggerNode
                                        }
                                    >
                                        <PasswordInput
                                            name="password"
                                            label="Password"
                                            onBlur={handleBlur}
                                            onChangeField={handlePassword}
                                        />
                                    </Tooltip>
                                </TooltipDesign>
                                <PasswordInput
                                    name="retype_password"
                                    label="Re-Type Password"
                                />

                                <CheckboxField name="terms_condition">
                                    By creating an account you agree to the{' '}
                                    <a
                                        href="https://www.taxrise.com/terms-of-use/"
                                        className="text-underline"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        terms of use
                                    </a>{' '}
                                    and our{' '}
                                    <a
                                        href="https://www.taxrise.com/privacy-policy/"
                                        className="text-underline"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        privacy policy.
                                    </a>
                                </CheckboxField>

                                <Button
                                    block
                                    type="primary"
                                    htmlType="submit"
                                    className="mt-16 mb-16"
                                    // disabled={!isValid}
                                    onClick={handleSubmit}
                                    loading={isSubmitting}
                                >
                                    Sign Up
                                </Button>
                            </form>
                        )}
                    </Formik>

                    <p className="text-center">
                        Already have an account?{' '}
                        <Link to="/" className="text-underline">
                            Sign In
                        </Link>
                    </p>
                </ContentWrap>
            </section>
            <Version />
        </Wrapper>
    );
}

const validationRules = Yup.object().shape({
    case_id: Yup.number()
        .typeError('Invalid case id')
        .required('Please enter case id'),
    email: Yup.string()
        .matches(EMAIL_REGEX, {
            excludeEmptyString: true,
            message: 'Invalid Email',
        })
        .required('Please enter email'),
    password: Yup.string()
        .required('No password provided.')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(LETTER_CASES_PATTERN, 'A mixture of both uppercase and lowercase letters')
        .matches(CHARACTERS_TYPE_PATTERN, 'A mixture of letters and numbers')
        .matches(SPECIAL_SYMBOLS_PATTERN, 'Inclusion of at least one special character, e.g.,!@#]')
        .matches(FORBIDDEN_SPECIAL_SYMBOLS_PATTERN, 'Do not use < or > in your password!'),
    retype_password: Yup.string()
        .required('No password provided.')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .oneOf([Yup.ref('password'), null], 'Password must match'),
    terms_condition: Yup.boolean().oneOf(
        [true],
        'Must Accept Terms and Conditions'
    ),
});

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 122px);
    form {
        @media (max-width: 767px) {
            padding: 0 32px;
        }
    }
    .width-660 {
        .inner-header {
            max-width: 660px;
        }
    }
`;

const TooltipDesign = styled.div`
    .ant-tooltip-inner {
        background: #393939;
        border-radius: 4px;
        height: auto;
        width: 490px;
        padding: 0;
    }
`;

export default SignUp;
