import { useContext, useState } from 'react';
import { Formik } from 'formik';
import { TextInput, PasswordInput } from 'components/FormFields';
import Button from 'components/Button';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { endpoints } from 'api';
import paths from 'routes/paths';
import { UserDetailContext } from 'contexts';
import store from 'store';
import { PageHeader } from 'components/Header';
import Version from 'components/Version';
import Message from 'components/Message';
import { ContentWrap } from 'components/Layout';
import { useApi } from 'api';
import jwt_decode from 'jwt-decode';
import * as Sentry from '@sentry/react';
import { FormattedText } from 'utilities';

const initialValues = {
    case_id: '',
    password: '',
};

const validationRules = Yup.object().shape({
    case_id: Yup.number()
        .typeError('Invalid case id')
        .required('Please enter case id'),
    password: Yup.string().required('Please give password'),
});

function HardCodedLogin(props) {
    const history = useHistory();
    const { dispatchUserDetail } = useContext(UserDetailContext);
    const [api] = useApi(false);

    const [errorMsg, setErrorMsg] = useState();
    const [showErrMsg, hideErrMsg] = useState(false);

    const forgotPassword = async (case_id) => {
        try {
            const params = {
                case_id: case_id,
                // eslint-disable-next-line no-undef
                redirect_url: `${process.env['REACT_APP_PASSWORD_REDIRECT_URL']}`,
                // eslint-disable-next-line no-undef
                email_app_name: `${process.env['REACT_APP_EMAIL_APP_NAME']}`,
            };
            await api.post(`${endpoints.FORGOT_PASSWORD_INIT}`, params);
        } catch (error) {
            const { data } = error.response;
            const result = JSON.parse(data.detail);
            const { msg } = result;
            setErrorMsg(msg);
            hideErrMsg(true);
        }
    };
    const login = async (data) => {
        const { case_id, password } = data;
        try {
            const body = new URLSearchParams();
            body.append('password', password);
            body.append('username', case_id);

            const req = await api.post(endpoints.TOKEN_HARDCODED, body);

            const res = await req.data;
            if (res) {
                const { access_token, token_type } = res;
                store.set('access_token', access_token);
                store.set('token_type', token_type);

                // Decode user details from token using jwt decoder
                const userDetails = jwt_decode(access_token);

                // Update Context with user details;
                dispatchUserDetail(userDetails);

                // Set Sentry user
                Sentry.setUser({
                    id: userDetails.case_id,
                    email: userDetails.email,
                    username: `${userDetails.first_name} ${userDetails.middle_name} ${userDetails.last_name}`,
                });

                history.push(paths.DASHBOARD);
            }
        } catch (error) {
            const { data } = error.response;
            const result = JSON.parse(data.detail);
            const { msg, is_email_verified, force_pass_reset } = result;
            setErrorMsg(msg);
            hideErrMsg(true);
            if (!is_email_verified && is_email_verified !== undefined) {
                store.set('case_id', case_id);
                store.set('key', 'only_resend');
                setTimeout(() => history.push(paths.EMAIL_RESEND), 5000);
            }
            if (force_pass_reset !== undefined && force_pass_reset === true) {
                forgotPassword(case_id);
            }
        }
    };

    return (
        <Wrapper>
            <section style={{ flex: 1 }}>
                <PageHeader>
                    <h1 className="mb-8">
                        <FormattedText
                            id="login.header"
                            defaultMessage="Sign in to your Taxrise client portal"
                        />
                    </h1>

                    <p className="mb-0">
                        Please enter your sign in details to continue.
                    </p>
                </PageHeader>
                <ContentWrap width="490px">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={login}
                        validationSchema={validationRules}
                        validateOnMount
                    >
                        {({ isValid, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit}>
                                {showErrMsg === true && (
                                    <Message message={errorMsg} />
                                )}
                                <TextInput name="case_id" label="Case ID" />
                                <PasswordInput
                                    name="password"
                                    label="Password"
                                />
                                <Button
                                    block
                                    type="primary"
                                    htmlType="submit"
                                    className="mt-16"
                                    disabled={!isValid}
                                    onClick={handleSubmit}
                                    loading={isSubmitting}
                                >
                                    Sign In
                                </Button>
                            </form>
                        )}
                    </Formik>

                    <p className="text-center mt-16 text-bold text-underline">
                        <Link to={paths.FORGOT_PASSWORD}>Forgot Password?</Link>
                    </p>

                    <p className="text-center">
                        Don’t have an account?{' '}
                        <Link to={paths.SIGN_UP} className="text-underline">
                            Sign Up
                        </Link>
                    </p>
                </ContentWrap>
            </section>
            <Version />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 122px);
    form {
        @media (max-width: 767px) {
            padding: 0 32px;
        }
    }
`;

export default HardCodedLogin;
