import { Suspense, lazy, useContext } from 'react';
import Dashboard from 'pages/Dashboard';
import Login, { HardCodedLogin } from 'pages/Login';
import SignUpProcess from 'pages/SignUpProcess';
import ResetPassword from 'pages/ResetPassword';
import { Switch } from 'react-router-dom';
import path from './paths';
import { SpinLoader } from 'components/Loader';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { PageNotFound, NotAuthorized } from 'utilities';
import { FeatureContext } from 'contexts';

// Code splitting page components
const Documents = lazy(() => import('pages/Documents'));
const Payments = lazy(() => import('pages/Payments'));
const MakePayment = lazy(() => import('pages/Payments/MakePayment'));
const Transcript = lazy(() => import('pages/Transcript'));
const TranscriptRedAppointment = lazy(() => import('pages/Calendly'));
const VerifilinkRedAppointment = lazy(() => import('pages/Calendly'));
const Faq = lazy(() => import('pages/Faq'));
const Contact = lazy(() => import('pages/Contact'));
const PaymentMethods = lazy(() => import('pages/PaymentMethodStep'));
const VerifilinkSummary = lazy(() => import('pages/Summary'));
const FinancialTransaction = lazy(() => import('pages/Clients/FinancialTransaction'));
const CaseStatus = lazy(() => import('pages/CaseStatus'));

const Routes = () => {
    const { featureStatus } = useContext(FeatureContext);

    return (
        <Suspense fallback={<SpinLoader style={{ margin: '35px auto' }} />}>
            <Switch>
                <PrivateRoute exact path={path.VERIFILINK_SUMMARY}>
                    <VerifilinkSummary />
                </PrivateRoute>
                <PrivateRoute exact path={path.FAQ}>
                    <Faq />
                </PrivateRoute>
                <PrivateRoute exact path={path.CONTACT}>
                    <Contact />
                </PrivateRoute>
                <PrivateRoute exact path={path.TRANSCRIPT}>
                    {featureStatus.transcript ? (
                        <Transcript />
                    ) : (
                        <NotAuthorized />
                    )}
                </PrivateRoute>
                <PrivateRoute exact path={path.TRANSCRIPT_RED_APPOINTMENT}>
                    <TranscriptRedAppointment />
                </PrivateRoute>
                <PrivateRoute exact path={path.VERIFILINK_RED_APPOINTMENT}>
                    <VerifilinkRedAppointment />
                </PrivateRoute>
                <PrivateRoute exact path={path.MAKE_PAYMENTS}>
                    <MakePayment />
                </PrivateRoute>
                <PrivateRoute exact path={path.FINANCIAL_SUMMARY}>
                    <FinancialTransaction />
                </PrivateRoute>
                <PrivateRoute exact path={path.PAYMENTS}>
                    <Payments />
                </PrivateRoute>
                <PrivateRoute exact path={path.DOCUMENTS}>
                    <Documents />
                </PrivateRoute>
                <PrivateRoute exact path={path.DASHBOARD}>
                    <Dashboard />
                </PrivateRoute>
                <PrivateRoute exact path={path.CASE_STATUS}>
                    <CaseStatus />
                </PrivateRoute>
                <PrivateRoute path={path.PAYMENT_METHOD}>
                    <PaymentMethods />
                </PrivateRoute>
                <PublicRoute path={path.FORGOT_PASSWORD}>
                    <ResetPassword />
                </PublicRoute>
                <PublicRoute path={path.SIGN_UP}>
                    <SignUpProcess />
                </PublicRoute>
                <PublicRoute exact path={path.LOGIN}>
                    <Login />
                </PublicRoute>
                <PublicRoute exact path={path.LOGIN_HARDCODED}>
                    {process.env['REACT_APP_HARDCODED_LOGIN'] === '1' ? (
                        <HardCodedLogin />
                    ) : (
                        <PageNotFound />
                    )}
                </PublicRoute>
                <PublicRoute path="*">
                    <PageNotFound />
                </PublicRoute>
            </Switch>
        </Suspense>
    );
};

export default Routes;
