import { useApi, endpoints } from 'api';
import { UserDetailContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';
import FeatureContext from './FeatureContext';

function FeatureProvider({ children }) {
    const [featureStatus, setFeatureStatus] = useState({
        verifilink: false,
        transcript: false,
        vlink_complete: false,
        show433: false,
    });

    const { userDetailInfo } = useContext(UserDetailContext);
    const [api] = useApi();

    useEffect(() => {
        const getUserFeatureStatus = async (caseId) => {
            try {
                const req = await api({
                    url: endpoints.FEATURE_STATUS,
                    params: {
                        'case-id': caseId,
                    },
                });

                const res = await req.data;

                const {
                    transcript,
                    verifilink,
                    vlink_complete,
                    show433,
                    ...rest
                } = res;

                // Need refactoring
                setFeatureStatus({
                    transcript:
                        typeof transcript === 'boolean' ? transcript : false,
                    verifilink:
                        typeof verifilink === 'boolean' ? verifilink : false,
                    vlink_complete:
                        typeof vlink_complete === 'boolean'
                            ? vlink_complete
                            : false,
                    show433: typeof show433 === 'boolean' ? show433 : false,
                    ...rest,
                });
            } catch (error) {
                console.log(error);
            }
        };

        if (userDetailInfo['case_id']) {
            getUserFeatureStatus(userDetailInfo['case_id']);
        }
    }, [userDetailInfo, api]);

    const updateFeatureStatus = (status) => {
        const { transcript, verifilink } = status;

        if (typeof transcript === 'boolean') {
            setFeatureStatus((stat) => ({ ...stat, transcript }));
        }

        if (typeof verifilink === 'boolean') {
            setFeatureStatus((stat) => ({ ...stat, verifilink }));
        }
    };

    return (
        <FeatureContext.Provider value={{ featureStatus, updateFeatureStatus }}>
            {children}
        </FeatureContext.Provider>
    );
}

export default FeatureProvider;
