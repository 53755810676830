import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useApi, endpoints } from 'api';
import paths from 'routes/paths';
import store from 'store';

function EmailVerification(props) {
    const history = useHistory();
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get('token');
    const [api] = useApi(false);

    useEffect(() => {
        const emailValidate = async (data) => {
            try {
                const req = await api({
                    method: 'POST',
                    url: endpoints.EMAIL_VERIFICATION,
                    data,
                });

                const res = await req.data;

                if (res) {
                    history.push(paths.LOGIN);
                }
            } catch (error) {
                store.set('invalid', 'invalid_token');
                setTimeout(() => history.push(paths.EMAIL_RESEND), 0);
            }
        };
        if (token) {
            // dummy added as backend asked to do this
            const params = {
                token: token,
                dummy: 'test',
            };
            emailValidate(params);
        }
    }, [token, api, history]);

    return <Wrapper></Wrapper>;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 122px);
    form {
        @media (max-width: 767px) {
            padding: 0 32px;
        }
    }
`;

export default EmailVerification;
