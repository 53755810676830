import Tab, { TabContent } from 'components/Tab';
import { Fragment, useContext, useEffect, useState } from 'react';
import ActivitiesTab from './ActivitiesTab';
import TasksTab from './TasksTab';
import CompletedTab from './CompletedTab';
import { PageHeader } from 'components/Header';
import styled from 'styled-components';
import { ContentWrap } from 'components/Layout';
import { UserDetailContext } from 'contexts';
import { TODO_TASKS } from 'const';
import { useApi, endpoints } from 'api';
import Badge from 'components/Badge';
import { FormattedText } from 'utilities';

function Dashboard() {
    const [pendingTasks, updatePendingTasks] = useState([]);
    const [completedTasks, updateCompletedTasks] = useState([]);
    const [tasksAvailable, setTasksAvailable] = useState(false);
    const [pendingTaskCompleted, setPendingTaskCompleted] = useState(false);

    const { userDetailInfo } = useContext(UserDetailContext);
    const [api] = useApi();

    useEffect(() => {
        // set hotjar
        if (
            window['hj'] &&
            typeof window.hj === 'function' &&
            userDetailInfo['case_id']
        ) {
            window.hj('identify', userDetailInfo.case_id, {});
        }
    }, [userDetailInfo]);

    useEffect(() => {
        const getToDoStatus = async () => {
            try {
                const req = await api({ url: endpoints.TO_DO_STATUS });
                const res = await req.data;

                if (res['data']) {
                    divideTasks(res.data.id);
                }
            } catch (error) {
                console.log(error);
            }
        };

        getToDoStatus();
    }, [api]);

    const divideTasks = (id) => {
        // Pending and Completed tasks are unavailable when id is negative or null or not available
        if (!id || id < 0) {
            return;
        }

        // Set Tasks are available
        setTasksAvailable(true);

        // When id = 999 then all tasks are completed!
        if (id === 999 || id === '999') {
            setPendingTaskCompleted(true);
            updateCompletedTasks(TODO_TASKS);

            return;
        }

        let pendingTaskIndex = undefined;
        const completedTasks = [];

        // Process completed tasks
        for (let i = 0; i < TODO_TASKS.length; i++) {
            if (TODO_TASKS[i].id === id) {
                pendingTaskIndex = i;
                break;
            } else {
                completedTasks.push(TODO_TASKS[i]);
            }
        }

        if (pendingTaskIndex !== undefined) {
            updatePendingTasks([TODO_TASKS[pendingTaskIndex]]);
        }

        updateCompletedTasks(completedTasks);
    };

    const tasksTitle = (
        <TasksBadge
            count={pendingTasks.length}
            offset={[4, 0]}
            style={{ backgroundColor: '#F36D28' }}
        >
            <span>Tasks</span>
        </TasksBadge>
    );

    return (
        <Fragment>
            <PageHeader className="mb-0">
                <h1>
                    Hi {userDetailInfo.first_name}, welcome to your{' '}
                    <FormattedText id="app.brand" defaultValue="TaxRise" />
                    &nbsp;client portal!
                </h1>
                <p>Welcome to your personalized dashoard!</p>
            </PageHeader>

            <DashboardTab defaultActiveKey={'current'} centered>
                <TabContent tab="Activities" key="activities">
                    <ContentWrap width="1012px">
                        <ActivitiesTab />
                    </ContentWrap>
                </TabContent>

                <TabContent tab={tasksTitle} key="tasks">
                    <ContentWrap width="1012px">
                        <TasksTab
                            tasks={pendingTasks}
                            available={tasksAvailable}
                            completed={pendingTaskCompleted}
                        />
                    </ContentWrap>
                </TabContent>

                <TabContent tab="Completed" key="completed">
                    <ContentWrap width="1012px">
                        <CompletedTab
                            tasks={completedTasks}
                            available={tasksAvailable}
                        />
                    </ContentWrap>
                </TabContent>
            </DashboardTab>
        </Fragment>
    );
}

const DashboardTab = styled(Tab)`
    .ant-tabs-nav {
        padding-left: 0;
        .ant-tabs-nav-wrap {
            max-width: 1012px !important;
            margin: 0 auto;
            padding: 0 8px;
        }
    }

    .ant-tabs-content {
        .ant-tabs-tabpane {
            @media (min-width: 768px) {
                padding: 48px 16px;
            }
        }
    }
`;

const TasksBadge = styled(Badge)`
    span {
        color: #fff;
    }

    .ant-badge-count {
        min-width: 12px;
        height: 12px;
        padding: 0 2px;
        font-size: 9px;
        line-height: 12px;

        .ant-scroll-number-only,
        .ant-scroll-number-only > p.ant-scroll-number-only-unit {
            height: 12px;

            .ant-scroll-number-only-unit {
                line-height: 13px;
            }
        }
    }
`;

export default Dashboard;
