import { Tabs } from 'antd';
import styled from 'styled-components';

function Tab({ children, ...props }) {
    return (
        <TabWrapper>
            <TabsStyled {...props}>{children}</TabsStyled>
        </TabWrapper>
    );
}

const TabWrapper = styled.div`
    max-width: 100vw;
`;

const TabsStyled = styled(Tabs)`
    .ant-tabs-nav {
        padding-left: 32px;
        margin-bottom: 0;
        background: ${({ theme }) => theme.tabNavBg};

        &::before {
            border-bottom: 1px solid ${({ theme }) => theme.tabNavBg};
        }
        .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
                .ant-tabs-tab {
                    padding: 12px 16px 8px;
                    min-width: 115px;
                    margin-right: 1px;
                    margin-left: 0px;
                    border-bottom: 2px solid
                        ${({ theme }) => theme.tabIndicator};

                    .ant-tabs-tab-btn {
                        color: ${({ theme }) => theme.tabColor};
                        font-size: 14px;
                    }

                    &.ant-tabs-tab-active {
                        .ant-tabs-tab-btn {
                            color: ${({ theme }) => theme.tabActiveColor};
                            font-weight: 600;
                        }
                    }
                }

                .ant-tabs-ink-bar {
                    background: ${({ theme }) => theme.tabActiveIndicator};
                }
            }
        }
    }
`;

export default Tab;
